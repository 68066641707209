
    @import "config.scss";
 
.container {
  position: relative;
}
.steps {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.statusContainer {
  padding-left: 25px;
  position: relative;
  .dot {
    position: absolute;
    left: 0px;
    top: 5px;
    padding: 2px;
  }
  .activeDot {
    border: 1px solid var(--color-primary);
    border-radius: 100%;
  }
}
.divider {
  width: 1px !important;
  height: calc(100% - 50px) !important;
  background-color: white !important;
  position: absolute !important;
  left: 7px !important;
  top: 9px !important;
}
