
    @import "config.scss";
 
.btn {
  margin-left: 0.5rem;
  background: transparent;
  border: none;
  cursor: pointer;
}
.productGalleryCardDesktop {
  background-color: var(--color-grey);
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: var(--br-medium);
  transition: 0.2s all;
  height: 100%;
  width: 10rem;
  margin: auto;

  &:hover {
    background-color: var(--color-grey-light);
  }

  @include for-desktop-up {
    width: 13.2rem;
  }

  .image {
    position: relative;
    flex: 0;
    background-color: white;
    border-radius: var(--br-medium) var(--br-medium) 0 0;
    width: 100%;

    .img {
      position: relative;
      height: 11.5rem;
      margin-top: 2.2rem;
      margin-inline: auto;
      object-fit: cover;

      & > * {
        width: 100%;
        height: 100%;
      }

      @include for-desktop-up {
        width: 100%;
      }
    }

    .name {
      height: 4rem;
      padding-right: 20px;
    }
  }

  .withoutInterestContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    white-space: nowrap;
  }

  .discount {
    background-color: var(--color-discount);
    z-index: var(--z-content-bottom);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: var(--br-medium);
    padding: 10px;
  }
}

.brand {
  position: absolute;
  right: 0;
  transform: scale(0.7);
}

.favorite {
  position: absolute;
  bottom: 0;
  z-index: 2;
  right: 0;
  transform: scale(0.7);
  width: 2.5rem;
}

.content {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  margin-bottom: 0.5rem;
  padding: 0 1rem;
  text-align: start;
  gap: 1rem;
  width: 100%;

  @include for-desktop-up {
    padding: 0.938rem;
  }
}

.badge {
  position: absolute;
  padding: 0.5rem;
  padding-right: 0.7rem;
  font-size: 0.7rem;
  border-radius: 0 1rem 0 0;
  bottom: 0;
  font-weight: 500;
}
