
    @import "config.scss";
 
.container {
  background-color: var(--color-background-container);
  border-radius: 25px;
  padding: clamp(12px, 8vw, 30px) clamp(12px, 3vw, 30px);
  margin-bottom: 25px;
  color: white;
  display: flex;
  flex-direction: column;
  border: 1px solid transparent;

  .head {
    display: flex;
    justify-content: space-around;
  }

  .cover {
    height: 0px;
    overflow: hidden;
    width: 85%;
    align-self: center;
    transition: all ease-in-out 0.5s;
  }

  .image {
    overflow: hidden;
    border-radius: 20px;
    height: 6rem;
    width: 6rem;

    img {
      // height: 100%;
    }
  }

  .title {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: space-between;
    width: 40%;
  }

  .state {
    display: flex;
  }

  .detailButton {
    display: flex;
    align-items: center;
  }
}

.open {
  border: 1px solid var(--color-primary);
}
